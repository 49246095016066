import { LockState, useVaultStore } from '@/store/vault';
import { useAuthStore } from '@/store/auth';

export default defineNuxtRouteMiddleware(async (to, _from) => {
  const vaultStore = useVaultStore();
  const authStore = useAuthStore();

  // redirect the user to the login page
  if (vaultStore.state === LockState.Locked || !authStore.user) {
    logger().info(
      'middleware/auth.ts',
      'defineNuxtRouteMiddleware()',
      `Vault locked. Redirecting from "${to.path}" to login page`
    );

    return go({
      path: '/',
      query: {
        redirect: to.fullPath,
      },
    });
  }
});
